import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Wrapper } from '../../../component';
import useAuth from '../../../hooks/useAuth';
import { Product } from './types';

function GetProduct() {
  const { authToken } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get<Product>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/products/${id}`,
          { headers }
        );
        setProduct(response.data);
      } catch (error) {
        console.error("Error al obtener los datos del producto:", error);
      }
    };

    fetchProductData();
  }, [id, authToken]);

  const formatCurrency = (value: number | string | undefined): string => {
    if (value === undefined || value === null || value === '') return 'No hay datos';
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    return numberValue.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
  };

  const renderKeyValue = (key: string, value: any) => {
    if (value === null || value === undefined || value === '') {
      value = 'No hay datos';
    }
    return (
      <p>
        <strong>{key}:</strong> {typeof value === 'object' ? JSON.stringify(value) : value}
      </p>
    );
  };

  return (
    <Wrapper isContent>
      {product && (
        <section className="p-4">
          <article className="flex flex-col gap-4">
            <div className="flex justify-center">
              <img
                src={product.images?.product_url || ''}
                alt={product.name}
                className="w-[300px] h-auto rounded-md"
              />
            </div>
            <div>
              {renderKeyValue('Name', product.name)}
              {renderKeyValue('Modelo', product.model)}
              {renderKeyValue('SKU', product.sku)}
              {renderKeyValue('Marca', product.brand)}
              {renderKeyValue('Categorías', product.categories.join(', '))}
              {product.price && renderKeyValue('Precio', formatCurrency(product.price))}
              {renderKeyValue('Stock', product.stock)}
              {renderKeyValue('Disponible', product.available ? 'Sí' : 'No')}
              {renderKeyValue('Creado', product.createdAt ? new Date(product.createdAt).toLocaleDateString() : 'Fecha no disponible')}
              {renderKeyValue('Última actualización', product.updatedAt ? new Date(product.updatedAt).toLocaleDateString() : 'Fecha no disponible')}
            </div>
            <div>
              <h3 className="font-semibold">Características:</h3>
              {product.caracteristics ? (
                Array.isArray(product.caracteristics) ? (
                  product.caracteristics.length > 0 ? (
                    <ul>
                      {product.caracteristics.map((caracteristic: string, index: number) => (
                        <li key={index}>{caracteristic}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No hay datos</p>
                  )
                ) : (
                  <p>{product.caracteristics}</p>
                )
              ) : (
                <p>No hay datos</p>
              )}
            </div>
            {product.description && (
              <div className="mt-4">
                <h3 className="font-semibold">Descripción:</h3>
                <div className="text-sm" dangerouslySetInnerHTML={{ __html: product.description }} />
              </div>
            )}
          </article>
          <div className="flex justify-center mt-4 gap-4">
            <Link to={`/dashboard/admin-products/update/${product.id}`} className="bg-tecnofi-primary text-[#fff] hover:bg-tecnofi-secondary rounded-[4px] px-[16px] py-[4px]">Editar</Link>
          </div>
        </section>
      )}
    </Wrapper>
  );
}

export default GetProduct;