import React, { useState, useEffect } from 'react';
import LogoNavbar from '../header/component/LogoNavbar';
import Login from './component/Login';
import SignUpEmpresa from './component/SignUpEmpresa';
import SignUpPersona from './component/SignUpPersona';
import Button from '../button/Button';
import { LoginProps } from './types';

function Account({ isLoginMode, toggleAuthMode, togglePolitics, toggleLogin }: LoginProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [typeClient, setTypeClient] = useState(true);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(`Email: ${email}, Password: ${password}, Username: ${username}`);
  };

  const handleToggleAuthMode = () => {
    toggleAuthMode();
  };

  const handleToggleTypeClient = () => {
    setTypeClient((prevType) => !prevType);
  };

  return (
    <section className="flex justify-center items-center h-full w-full text-tecnofi-text overflow-y-auto pb-10">
      {isLoginMode && (
        <img
          src="https://www.tecnoficom.com/wp-content/uploads/2024/05/Tecnoficom-LTDA.jpg"
          alt="Inicie Sesión"
          className="hidden md:flex rounded-[32px] object-cover h-full xl:w-[50%] lg:w-[60%] md:w-[65%] pe-5"
        />
      )}
      <div className={`relative ${isLoginMode ? 'xl:w-[50%] lg:w-[40%] md:w-[35%] h-full overflow-y-auto' : 'max-h-full h-full w-full'}`}>
        <article className="flex flex-col justify-center items-center gap-4 pb-4">
          <LogoNavbar />
          <div className="flex flex-wrap justify-center items-center gap-4">
            <h2 className="text-center text-[32px] font-medium">{isLoginMode ? 'Acceda' : 'Regístrese'}</h2>
            {!isLoginMode && (
              <Button
                variant="Primary"
                className="flex justify-center text-[16px] text-center font-medium mx-auto rounded-lg py-2 px-3 "
                onClick={handleToggleTypeClient}
              >
                {typeClient ? 'Soy persona natural' : 'Soy una empresa'}
              </Button>
            )}
          </div>
        </article>

        {isLoginMode ? (
          <Login toggleLogin={toggleLogin} />
        ) : (
          <>
            {typeClient ? <SignUpEmpresa togglePolitics={togglePolitics} /> : <SignUpPersona togglePolitics={togglePolitics} />}
          </>
        )}

        <p className="text-center text-sm mt-4">
          {isLoginMode ? '¿Aún no tiene una cuenta?' : '¿Ya tiene una cuenta?'}
          <button onClick={handleToggleAuthMode} className="text-tecnofi-primary ml-2 hover:underline focus:outline-none">
            {isLoginMode ? 'Regístrese' : 'Acceda'}
          </button>
        </p>
      </div>
    </section>
  );
}

export default Account;