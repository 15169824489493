import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { OneUser } from './types';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../component';
import useAuth from '../../../hooks/useAuth';

function AllUsers() {
  const { id } = useParams<{ id: string }>();
  const { authToken } = useAuth();
  const [users, setUsers] = useState<OneUser | null>(null);
  const navigator = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<OneUser>(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/${id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [id, authToken]);

  const activeUser = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/${id}`, 
        {}, 
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      console.log(response);
      navigator('/dashboard/clients');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    return date.toLocaleDateString('es-ES', options);
  };

  return (
    <section className="p-4">
      <h1 className="text-center text-2xl font-bold text-tecnofi-text px-8 py-4">
        Cliente {users && (users.profile.fullName)}
      </h1>
      <article className="w-full max-w-full overflow-x-scroll">
        {users && (
          <p>
            <p className="px-4 py-2 whitespace-nowrap text-sm font-medium text-tecnofi-text"><span className="font-semibold">Id del Usuario: </span>{users.getUser.id}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Nombre Completo: </span>{users.profile.fullName}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Identificacion de Empresa/Persona: </span>{users.profile.identificationNumber}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Telefono: </span>{users.profile.contactPhone}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Correo Electronico: </span>{users.getUser.email}</p>
            {users.profile.businessName && (<p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Nombre de la Empresa: </span>{users.profile.businessName}</p>)}
            {users.profile.billingEmail && (<p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Correo de Facturación: </span>{users.profile.billingEmail}</p>)}
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Cargo: </span>{users.profile.position}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Roles: </span>{users.getUser.clientRoles.join(', ')}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Estado del Cliente: </span>{users.getUser.clientStatus}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Tipo de Cliente: </span>{users.getUser.clientType}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">URL del RUT: </span>{users.profile.urlRUT}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Dirección Primaria: </span>{users.profile.primaryAddress}</p>
            {users.profile.secondaryAddress && (<p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Dirección Secundaria: </span>{users.profile.secondaryAddress}</p>)}
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Ciudad de Dirección: </span>{users.profile.location.municipality}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Persona Juridica: </span>{users.getUser.legalPersonType ? 'Yes' : 'No'}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Autorización de Tratamiento de Datos: </span>{users.profile.dataTreatmentAuthorization ? 'Yes' : 'No'}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Autorización a Notificaciones: </span>{users.profile.notificationAuthorization ? 'Yes' : 'No'}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Fecha de Creación: </span>{formatDate(users.profile.createdAt)}</p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text"><span className="font-semibold">Fecha de Actualización: </span>{formatDate(users.profile.updatedAt)}</p>
          </p>
        )}
        <div className="flex justify-center items-center gap-4">
          {users && users.getUser.clientStatus !== 'ACTIVE' && (
            <Button variant="Primary" className="rounded-lg mx-auto my-2 px-4 py-2" onClick={activeUser}>
              Activar
            </Button>
          )}
        </div>
      </article>
    </section>
  );
}

export default AllUsers;
