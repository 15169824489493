import React, { useEffect, useState } from 'react';
import Button from '../../button/Button';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

interface City {
  region: string;
  departmentDaneCode: string;
  department: string;
  municipalityDaneCode: string;
  municipality: string;
}

function SignUpEmpresa({ togglePolitics }: { togglePolitics: () => void }) {
  //Formulario
  const [fullName, setFullName] = useState('');
  const [position, setPosition] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [identificationNumber, setIdentificationNumber] = useState('');
  const [email, setEmail] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [rut, setRut] = useState<File | null>(null);
  const [clientType, setClientType] = useState<string | null>(null);
  const [primaryAddress, setPrimaryAddress] = useState('');
  const [secondaryAddress, setSecondaryAddress] = useState('');
  const [location, setLocation] = useState<City>();
  const [password, setPassword] = useState('');
  const [dataTreatmentAuthorization, setDataTreatmentAuthorization] = useState<boolean>(false);
  const [notificationAuthorization, setNotificationAuthorization] = useState<boolean>(false);

  const [cities, setCities] = useState<City[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [toggleSelected, setToggleSelected] = useState(false);
  const [toggleSelectedC, setToggleSelectedC] = useState(false);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get('https://www.datos.gov.co/resource/xdk5-pm3f.json')
        const cityList: City[] = response.data.map((item: any) => ({
          region: item.region,
          departmentDaneCode: item.c_digo_dane_del_departamento,
          department: item.departamento,
          municipalityDaneCode: item.c_digo_dane_del_municipio,
          municipality: item.municipio
        }));
        setCities(cityList);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    const userData = {
      fullName,
      position,
      contactPhone,
      businessName,
      identificationNumber: parseInt(identificationNumber, 10),
      email,
      billingEmail,
      clientType: clientType,
      primaryAddress,
      secondaryAddress,
      location: location,
      password,
      legalPersonType: true,
      dataTreatmentAuthorization: dataTreatmentAuthorization ? true : false,
      notificationAuthorization: notificationAuthorization ? true : false
    };
    formData.append('userData', JSON.stringify(userData));
    if (rut) {
      formData.append('rut', rut);
    }
    const formDataObject: Record<string, string | Blob> = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/register`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('¡Registro exitoso! Recibiras un correo pronto.');
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const filteredCities = cities.filter(city =>
    city.municipality.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleClientType = (client: string) => {
    setClientType(client);
    setToggleSelected(false);
  };

  const toggleSelectedCity = (city: City) => {
    setLocation(city);
    setToggleSelectedC(false);
  };

  const handleDataTreatmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataTreatmentAuthorization(e.target.checked);
  };

  const handleNotificationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationAuthorization(e.target.checked);
  };

  const handdlePolitics = () => {
    togglePolitics();
  }

  return (
    <section className="flex flex-col items-center w-full text-tecnofi-text">
      <ToastContainer />
      <form className="w-full" onSubmit={handleSubmit}>
        <section className="grid sm:grid-cols-3 justify-center items-center gap-4 mb-4">
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="nombre">
              Nombre Completo
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="nombre"
              type="text"
              placeholder="Nombre Completo"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="position">
              Cargo
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="position"
              type="text"
              placeholder="Cargo"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="telefono">
              Teléfono de Contacto
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="telefono"
              type="tel"
              placeholder="Teléfono de Contacto"
              value={contactPhone}
              onChange={(e) => setContactPhone(e.target.value)}
            />
          </article>
        </section>
        <section className="grid sm:grid-cols-3 justify-center items-center gap-4 mb-6">
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="nombreEmpresa">
              Razón Social
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="nombreEmpresa"
              type="text"
              placeholder="Razón Social"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="NIT">
              NIT de la Empresa
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="NIT"
              type="number"
              placeholder="NIT de la empresa"
              value={identificationNumber}
              onChange={(e) => setIdentificationNumber(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="email">
              Correo de Notificaciones
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="email"
              type="email"
              placeholder="Correo de Notificaciones"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </article>
        </section>
        <section className="grid sm:grid-cols-3 justify-center items-center gap-4 mb-6">
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="emailFac">
              Correo de Facturación
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="emailFac"
              type="email"
              placeholder="Correo de Facturación"
              value={billingEmail}
              onChange={(e) => setBillingEmail(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="file">
              Adjuntar archivo
            </label>
            <input
              className="hidden"
              id="file"
              type="file"
              onChange={(e) => setRut(e.target.files ? e.target.files[0] : null)}
            />
            <label
              htmlFor="file"
              className="flex justify-start items-center border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-faded-text cursor-pointer w-[full] px-4 h-[52px] bg-tecnofi-section">
              {rut ? <p className="text-tecnofi-text font-medium">Subido</p> : 'Sube tu RUT'}
            </label>
          </article>
          <article className="relative flex flex-col">
            <label className="flex text-base font-medium mb-2">
              Seleccionar el Tipo de Cliente
            </label>
            <label
              className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
              onClick={() => setToggleSelected(!toggleSelected)}
            >
              {clientType ? clientType : 'Seleccionar Cliente'}
            </label>
            {toggleSelected && (
              <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 overflow-hidden shadow-md">
                <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleClientType('CLIENT_FINAL')}>
                  Cliente final
                </div>
                <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleClientType('DISTRIBUTOR')}>
                  Distribuidor de Tecnología
                </div>
                <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleClientType('SOLUTION_INTEGRATOR')}>
                  Integrador de soluciones
                </div>
                <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleClientType('GOVERMENT')}>
                  Gobierno
                </div>
                <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleClientType('TECHNICAL')}>
                  Técnico
                </div>
              </div>
            )}
          </article>
        </section>
        <section className="grid sm:grid-cols-3 justify-center items-center gap-4 mb-6">
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="primaryAddress">
              Direccion Principal
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="primaryAddress"
              type="text"
              placeholder="Direccion Principal"
              value={primaryAddress}
              onChange={(e) => setPrimaryAddress(e.target.value)}
            />
          </article>
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="secondaryAddress">
              Direccion Secundaria (Opcional)
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="secondaryAddress"
              type="text"
              placeholder="NIT de la empresa"
              value={secondaryAddress}
              onChange={(e) => setSecondaryAddress(e.target.value)}
            />
          </article>
          <article className="relative flex flex-col">
            <label className="flex text-base font-medium mb-2">Selecciona tu Ciudad</label>
            <label
              className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
              onClick={() => setToggleSelectedC(!toggleSelectedC)}
            >
              {location?.municipality ? location?.municipality : 'Seleccionar Ciudad'}
            </label>

            {toggleSelectedC && (
              <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 overflow-hidden shadow-md max-h-60 overflow-y-auto">
                <input
                  type="text"
                  placeholder="Buscar ciudad..."
                  className="py-2 px-4 w-full border-b-[1px] border-tecnofi-primary focus:outline-none"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  autoComplete='off'
                />

                {filteredCities.map((city, index) => (
                  <div
                    key={index}
                    className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section"
                    onClick={() => toggleSelectedCity(city)}
                  >
                    {city.municipality}
                  </div>
                ))}
              </div>
            )}
          </article>
        </section>
        <section className="grid sm:grid-cols-2 justify-center items-center gap-4 mb-6">
          <article className="flex flex-col">
            <label className="flex text-base font-medium mb-2" htmlFor="password">
              Contraseña
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="password"
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </article>
          <article className="flex flex-col space-y-4 mt-8">
            <div className="flex items-center space-x-3">
              <input
                className="h-5 w-5 accent-tecnofi-primary border border-tecnofi-primary rounded-sm transition duration-300 ease-in-out"
                type="checkbox"
                id="dataTreatmentAuthorization"
                checked={dataTreatmentAuthorization}
                onChange={handleDataTreatmentChange}
              />
              <label className="text-base font-medium" htmlFor="dataTreatmentAuthorization">
                Autorización para el tratamiento de datos
              </label>
            </div>

            <div className="flex items-center space-x-3">
              <input
                className="h-5 w-5 accent-tecnofi-primary border border-tecnofi-primary rounded-sm transition duration-300 ease-in-out"
                type="checkbox"
                id="notificationAuthorization"
                checked={notificationAuthorization}
                onChange={handleNotificationChange}
              />
              <label className="text-base font-medium" htmlFor="notificationAuthorization">
                Autorización para notificaciones
              </label>
            </div>
          </article>
        </section>
        <p className="text-sm text-center mb-4">
          Al registrarse, usted acepta nuestras <span className="text-tecnofi-primary cursor-pointer hover:underline" onClick={handdlePolitics}>Políticas de Privacidad</span>, <span className="text-tecnofi-primary cursor-pointer hover:underline" onClick={handdlePolitics}>Términos y Condiciones</span> y el uso del correo electrónico como medio de notificaciones de novedades.
        </p>
        <Button
          variant="Primary"
          className="max-w-[180px] w-full flex justify-center text-[16px] text-center font-medium mx-auto rounded-lg py-2 px-3 mt-4"
          type="submit"
        >
          Regístrese
        </Button>
      </form>
    </section>
  );
}

export default SignUpEmpresa;
