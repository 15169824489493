import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Wrapper, Button } from "../../../component";
import useAuth from "../../../hooks/useAuth";
import { Brand, Product } from "./types";
import { Category } from "../../Product/component/types";

function UpdateProduct() {
  const { authToken } = useAuth();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product | null>(null);
  const [categoriesLevelOne, setCategoriesLevelOne] = useState<Category[]>([]);
  const [categoriesLevelTwo, setCategoriesLevelTwo] = useState<Category[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [toggleCategoryLevelOne, setToggleCategoryLevelOne] = useState(false);
  const [selectedCategoryLevelOne, setSelectedCategoryLevelOne] = useState<Category | null>(null);
  const [toggleCategoryLevelTwo, setToggleCategoryLevelTwo] = useState(false);
  const [selectedCategoryLevelTwo, setSelectedCategoryLevelTwo] = useState<Category | null>(null);
  const [toggleBrand, setToggleBrand] = useState(false);
  const [originalProduct, setOriginalProduct] = useState<Product | null>(null);
  const [updatedProduct, setUpdatedProduct] = useState<Product>({
    id: '',
    name: '',
    model: '',
    sku: '',
    price: 0,
    stock: 0,
    description: '',
    caracteristics: '',
    available: false,
    dataSheetURL: '',
    categories: [],
    brand: '',
    vat: 0,
    system: ''
  });

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const categoriesLevelOneResponse = await axios.get<Category[]>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/category?level=1`,
          { headers }
        );
        const categoriesLevelTwoResponse = await axios.get<Category[]>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/category?level=2`,
          { headers }
        );
        const brandsResponse = await axios.get<Brand[]>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/brand`,
          { headers }
        );
        setCategoriesLevelOne(categoriesLevelOneResponse.data);
        setCategoriesLevelTwo(categoriesLevelTwoResponse.data);
        setBrands(brandsResponse.data);

        const response = await axios.get<any>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/products/${id}`,
          { headers }
        );
        setProduct(response.data);
        setOriginalProduct(response.data);
        setUpdatedProduct({
          id: response.data.id || '',
          name: response.data.name || '',
          model: response.data.model || '',
          sku: response.data.sku || '',
          price: response.data.price || 0,
          stock: response.data.stock || 0,
          description: response.data.description || '',
          caracteristics: response.data.caracteristics || '',
          available: response.data.available || false,
          dataSheetURL: response.data.dataSheetURL || '',
          categories: response.data.categories || [],
          brand: response.data.brand || '',
          vat: response.data.vat || 0,
          system: response.data.system || '',
        });

      } catch (error) {
        console.error("Error al obtener los datos del producto:", error);
      }
    };

    fetchProductData();
  }, [id, authToken]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    if (id === 'available') {
      setUpdatedProduct({
        ...updatedProduct,
        [id]: value === 'true',
      });
      return;
    }
    setUpdatedProduct((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const changes: Partial<Product> = {};
  
    if (originalProduct) {
      for (const key in updatedProduct) {
        if (Object.prototype.hasOwnProperty.call(updatedProduct, key)) {
          const updatedValue = updatedProduct[key as keyof Product];
          const originalValue = originalProduct[key as keyof Product];
          if (updatedValue !== originalValue && updatedValue !== "" && updatedValue !== null) {
            if (key === "caracteristics") {
              if (typeof updatedValue === "string") {
                changes[key as keyof Product] = updatedValue.split(',').map(item => item.trim());
              } else if (Array.isArray(updatedValue)) {
                changes[key as keyof Product] = updatedValue;
              }
            } else {
              changes[key as keyof Product] = updatedValue;
            }
          }
        }
      }
    }
    console.log(changes);
  
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/products/${id}`,
        changes,
        { headers }
      );
      navigate(`/dashboard/admin-products/${id}`);
    } catch (error) {
      console.error("Error al actualizar el producto:", error);
    }
  };
  
  return (
    <Wrapper isContent>
      {product && updatedProduct && (
        <section className="p-4">
          <article className="flex flex-col gap-4">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="flex text-base font-medium mb-2" htmlFor="name">
                  Nombre
                </label>
                <input
                  className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  value={updatedProduct.name || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-4">
                <label className="flex text-base font-medium mb-2" htmlFor="model">
                  Modelo
                </label>
                <input
                  className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                  id="model"
                  type="text"
                  name="model"
                  placeholder="Modelo"
                  value={updatedProduct.model}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-4">
                <label className="flex text-base font-medium mb-2" htmlFor="sku">
                  SKU
                </label>
                <input
                  className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                  id="sku"
                  type="text"
                  name="sku"
                  placeholder="SKU"
                  value={updatedProduct.sku || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-4">
                <label className="flex text-base font-medium mb-2" htmlFor="price">
                  Precio
                </label>
                <input
                  className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                  id="price"
                  type="number"
                  name="price"
                  placeholder="Precio"
                  value={updatedProduct.price}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-4">
                <label className="flex text-base font-medium mb-2" htmlFor="stock">
                  Stock
                </label>
                <input
                  className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                  id="stock"
                  type="text"
                  name="stock"
                  placeholder="Stock"
                  value={updatedProduct.stock}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-4">
                <label className="flex text-base font-medium mb-2" htmlFor="description">
                  Descripción
                </label>
                <textarea
                  className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                  id="description"
                  name="description"
                  placeholder="Descripción"
                  value={updatedProduct.description || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-4">
                <label className="flex text-base font-medium mb-2" htmlFor="caracteristics">
                  Características
                </label>
                <textarea
                  className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                  id="caracteristics"
                  name="caracteristics"
                  placeholder="Características"
                  value={Array.isArray(updatedProduct.caracteristics)
                    ? updatedProduct.caracteristics.join(', ')
                    : updatedProduct.caracteristics || ''}
                  onChange={handleInputChange}
                />
              </div>

              <article className="relative flex flex-col">
                <label className="flex text-base font-medium mb-2">
                  Seleccionar Categoría Nivel 1
                </label>
                <label
                  className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
                  onClick={() => setToggleCategoryLevelOne(!toggleCategoryLevelOne)}
                >
                  {selectedCategoryLevelOne ? `${selectedCategoryLevelOne.name}` : 'Seleccionar Categoría Nivel 1'}
                </label>
                {toggleCategoryLevelOne && (
                  <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 shadow-md max-h-60 overflow-y-auto max-w-[300px]">
                    {categoriesLevelOne.map((category) => (
                      <div
                        key={category.id}
                        className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section"
                        onClick={() => {
                          setSelectedCategoryLevelOne(category);
                          setToggleCategoryLevelOne(false);
                          setUpdatedProduct((prevProduct) => ({
                            ...prevProduct,
                            categories: [String(category.id), prevProduct.categories[1] || ''],
                          }));
                        }}
                      >
                        {category.name}
                      </div>
                    ))}
                  </div>
                )}
              </article>

              <article className="relative flex flex-col">
                <label className="flex text-base font-medium mb-2">
                  Seleccionar Categoría Nivel 2
                </label>
                <label
                  className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
                  onClick={() => setToggleCategoryLevelTwo(!toggleCategoryLevelTwo)}
                >
                  {selectedCategoryLevelTwo ? `${selectedCategoryLevelTwo.name}` : 'Seleccionar Categoría Nivel 2'}
                </label>
                {toggleCategoryLevelTwo && (
                  <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 shadow-md max-h-60 overflow-y-auto max-w-[300px]">
                    {categoriesLevelTwo.map((category) => (
                      <div
                        key={category.id}
                        className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section"
                        onClick={() => {
                          setSelectedCategoryLevelTwo(category);
                          setToggleCategoryLevelTwo(false);
                          setUpdatedProduct((prevProduct) => ({
                            ...prevProduct,
                            categories: [prevProduct.categories[0] || '', String(category.id)],
                          }));
                        }}
                      >
                        {category.name}
                      </div>
                    ))}
                  </div>
                )}
              </article>

              <article className="relative flex flex-col mt-4">
                <label className="flex text-base font-medium mb-2">
                  Seleccionar Marca
                </label>
                <label
                  className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
                  onClick={() => setToggleBrand(!toggleBrand)}
                >
                  {selectedBrand ? `${selectedBrand.name}` : 'Seleccionar Marca'}
                </label>
                {toggleBrand && (
                  <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 shadow-md max-h-60 overflow-y-auto max-w-[300px]">
                    {brands.map((brand) => (
                      <div
                        key={brand.id}
                        className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section"
                        onClick={() => {
                          setSelectedBrand(brand);
                          setToggleBrand(false);
                          setUpdatedProduct((prevProduct) => ({
                            ...prevProduct,
                            brand: String(brand.id),
                          }));
                        }}
                      >
                        {brand.name}
                      </div>
                    ))}
                  </div>
                )}
              </article>

              <div className="mb-4">
                <label className="flex text-base font-medium mb-2" htmlFor="available">
                  Disponible
                </label>
                <select
                  className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                  id="available"
                  name="available"
                  value={updatedProduct.available ? 'true' : 'false'}
                  onChange={handleInputChange}
                >
                  <option value="true">Sí</option>
                  <option value="false">No</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="flex text-base font-medium mb-2" htmlFor="dataSheetURL">
                  URL de la ficha técnica
                </label>
                <input
                  className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                  id="dataSheetURL"
                  name="dataSheetURL"
                  type="url"
                  placeholder="URL de la ficha técnica"
                  value={updatedProduct.dataSheetURL || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex justify-center mt-4 gap-4">
                <Button type="submit" variant="Primary" className="rounded-[4px] px-[16px] py-[4px]">Guardar cambios</Button>
                <Link to={`/dashboard/admin-products/${product.id}`} className="bg-tecnofi-section rounded-[4px] px-[16px] py-[4px]">Cancelar</Link>
              </div>
            </form>
          </article>
        </section>
      )}
    </Wrapper>
  );
}

export default UpdateProduct;
