import React, { useEffect, useState } from 'react';
import { Button, Wrapper } from '../../../component';
import { Link, useParams } from 'react-router-dom';
import { Producto, Brand } from './types';
import axios from 'axios';
import { ProductProps } from '../types';
import { Helmet } from 'react-helmet';
import useAuth from '../../../hooks/useAuth';
import { Pagination, Product } from '../../../component/fetch/types';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';

function ProductData({ isAuthenticated, addToCart, toggleLogin }: ProductProps) {
  const { authToken } = useAuth()
  const { id } = useParams();
  const [product, setProduct] = useState<Product>();
  const [productData, setProductData] = useState<Producto | null>(null);
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [marcaId, setMarcaId] = useState<number | null>(null);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get<Product>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/products/${id}`,
          { headers }
        );

        const marcaResponse = await axios.get<Brand[]>(`${process.env.REACT_APP_API_URL}/marcas`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          }
        });

        const marcaEncontrada = marcaResponse.data.find(marca => marca.nombre === response.data.brand);
        if (marcaEncontrada) {
          setMarcaId(marcaEncontrada.id);
        }
        setProduct(response.data)
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const addShoppingCartProduct = async (id: string, quantity: number) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/shopping-cart/add`,
        { items: [{ productId: id, quantity: quantity }] },
        { headers }
      );
      toast.info('El producto se ha añadido al carrito');
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
    }
  };

  const addWishlistsProduct = async (id: string) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/wishlists/add/${id}`,
        {},
        { headers }
      );
      toast.info('El producto se ha añadido a la lista de deseos');
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
    }
  };

  const handleClick = (imageUrl: string) => {
    setMainImage(imageUrl);
  };

  const handleAddToCart = () => {
    if (addToCart && productData) {
      addToCart(productData, quantity);
    } else {
      console.error('addToCart function is not defined or productData is null/undefined.');
    }
  };

  const handleLogin = () => {
    if (toggleLogin) {
      toggleLogin();
    } else {
      console.error('toggleLogin function is not defined.');
    }
  }

  function formatCurrency(value: number | string | undefined): string {
    if (value === undefined) return '';
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    return numberValue.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
  }

  return (
    <>
      {product && (
        <>
          <Helmet>
            <title>Tecnoficom | {product.name}</title>
            <meta name="description" content={`Detalles del producto ${product.name}, de la marca ${product.brand} en Tecnoficom. Descubre sus características, modelos disponibles y más.`} />
            <meta name="keywords" content={`${product.name}, ${product.brand}, ${product.model}, productos, telecomunicaciones, ventas, precios, servicio, nacional, infraestructura, profesionales, técnicos, soluciones, conectividad, redes, tecnología, calidad`} />
          </Helmet>
          <ToastContainer />
          <section className="bg-tecnofi-section pt-[50px] pb-[30px]">
            <Wrapper isContent>
              <article key={product.id} className="flex md:flex-row flex-col gap-[20px]">
                <figure className="flex flex-col justify-center items-center rounded-[8px] gap-[12px]">
                  <img src={product.images.product_url || ''} alt={product.name} className="bg-tecnofi-background w-[300px] min-h-40 rounded-[12px]" />
                  <div className="flex gap-[12px] overflow-x-auto w-[300px]">
                    {product.images && product.images.views_url && product.images.views_url.map((image, index) => (
                      <img key={index} src={image} alt={`Referencias`} className="bg-tecnofi-background rounded-[12px] h-[75px] cursor-pointer" onClick={() => handleClick(image)} />
                    ))}
                  </div>
                </figure>
                <div className="text-tecnofi-text text-md">
                  <h2 className="font-semibold mt-[10px] text-3xl my-1">{product.name}</h2>
                  <p>{product.model}</p>
                  {isAuthenticated && product && product.price && (
                    <p className="font-bold text-tecnofi-primary text-3xl my-1">
                      COP: {formatCurrency(product.price)}
                    </p>
                  )}
                  {marcaId && <Link to={`/brand/${marcaId}/${encodeURIComponent(product.brand)}`} className="font-medium text-tecnofi-text hover:underline">{product.brand}</Link>}
                  {Array.isArray(product.caracteristics) && product.caracteristics.length > 0 ? (
                    <>
                      <h2 className="font-semibold text-tecnofi-text">Características</h2>
                      <ul>
                        {product.caracteristics.map((caracteristic, index) => (
                          <li key={index}>{caracteristic}</li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    typeof product.caracteristics === 'string' && product.caracteristics && (
                      <>
                        <h2 className="font-semibold text-tecnofi-text">Características</h2>
                        <p>{product.caracteristics}</p>
                      </>
                    )
                  )}
                  {isAuthenticated && (
                    <div className="flex gap-2 items-center mt-4">
                      <label htmlFor="quantity" className="font-medium text-tecnofi-text">Cantidad:</label>
                      <input
                        id="quantity"
                        type="number"
                        min="1"
                        value={quantity}
                        onChange={(e) => setQuantity(parseInt(e.target.value))}
                        className="border-[2px] border-tecnofi-primary rounded-[8px] w-[60px] py-2 px-3 bg-tecnofi-section focus:outline-none"
                      />
                    </div>
                  )}
                  {isAuthenticated ? (
                    <div className="flex md:flex-row flex-col justify-center items-center gap-2">
                      <Button
                        variant="Primary"
                        className="max-w-[180px] w-full flex justify-center text-[16px] text-center font-medium mx-auto rounded-lg mt-4 py-2 px-3"
                        onClick={async () => await addShoppingCartProduct(product.id, quantity)}
                      >
                        Agregar al carrito
                      </Button>
                      <Button
                        variant="Primary"
                        className="max-w-[180px] w-full flex justify-center items-center text-[16px] text-center font-medium mx-auto rounded-lg mt-4 py-2 px-3"
                        onClick={async () => await addWishlistsProduct(product.id)}
                      >
                        <MdOutlineFavoriteBorder size={26} fill="#FFF" />
                        <p>
                          Agregar a mis favoritos
                        </p>
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="Primary"
                      className="max-w-[220px] w-full flex justify-center text-[16px] text-center font-medium mx-auto rounded-lg mt-4 py-2 px-3"
                      onClick={handleLogin}
                    >
                      Acceder y Ver Precio
                    </Button>
                  )}
                </div>
              </article>
            </Wrapper>
          </section>
          {product.description && (
            <section className="pt-[24px] mb-[50px]">
              <Wrapper isContent>
                <article>
                  <h2 className="font-medium text-center text-tecnofi-text">Descripción</h2>
                  <div className="text-tecnofi-text" dangerouslySetInnerHTML={{ __html: product.description }} />
                </article>
              </Wrapper>
            </section>
          )}
        </>
      )}
    </>
  );
}

export default ProductData;
