import React, { useEffect, useState } from 'react';
import { Product } from './types';
import { Wrapper } from '../../../component';
import { toast, ToastContainer } from 'react-toastify';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface Category {
  id: number;
  name: string;
}

interface Brand {
  id: number;
  name: string;
}

const CreateProductForm = () => {
  const { authToken } = useAuth();
  const navigate = useNavigate();
  const [newProduct, setNewProduct] = useState<Product>({
    id: '',
    name: '',
    model: '',
    sku: null,
    description: null,
    caracteristics: [],
    price: 0,
    vat: 0,
    stock: 0,
    system: '',
    categories: [],
    available: false,
    dataSheetURL: null,
    brand: '',
    images: {
      product_url: null,
      views_url: null,
    },
  });
  const [categoriesLevelOne, setCategoriesLevelOne] = useState<Category[]>([]);
  const [categoriesLevelTwo, setCategoriesLevelTwo] = useState<Category[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [toggleCategoryLevelOne, setToggleCategoryLevelOne] = useState(false);
  const [selectedCategoryLevelOne, setSelectedCategoryLevelOne] = useState<Category | null>(null);
  const [toggleCategoryLevelTwo, setToggleCategoryLevelTwo] = useState(false);
  const [selectedCategoryLevelTwo, setSelectedCategoryLevelTwo] = useState<Category | null>(null);
  const [toggleBrand, setToggleBrand] = useState(false);
  const [productImage, setProductImage] = useState<File | null>(null);
  const [viewImages, setViewImages] = useState<Array<File> | null>(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const categoriesLevelOneResponse = await axios.get<Category[]>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/category?level=1`,
          { headers }
        );
        const categoriesLevelTwoResponse = await axios.get<Category[]>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/category?level=2`,
          { headers }
        );
        const brandsResponse = await axios.get<Brand[]>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/brand`,
          { headers }
        );

        setCategoriesLevelOne(categoriesLevelOneResponse.data);
        setCategoriesLevelTwo(categoriesLevelTwoResponse.data);
        setBrands(brandsResponse.data);
      } catch (error) {
        console.error('Error al obtener los datos del producto:', error);
      }
    };

    fetchProductData();
  }, [authToken]);

  const handleProductImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setProductImage(files[0]);
    }
  };
  const handleViewImagesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setViewImages((prev) => {
        if (prev === null) {
          return Array.from(files);
        }
        return [...prev, ...Array.from(files)];
      });
    }
  };
  const handleRemoveViewImage = (index: number) => {
    setViewImages((prev) => {
      if (prev === null) return prev;
      return prev.filter((_, i) => i !== index);
    });
  };
  const handleRemoveProductImage = () => {
    setProductImage(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [id]: value || '',
    }));
  };

  const handleCaracteristicsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      caracteristics: value ? value.split(',').map(item => item.trim()) : []
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const requiredFields = [
      'name',
      'model',
      'sku',
      'price',
      'vat',
      'stock',
      'description',
      'caracteristics',
      'brand',
      'categories',
    ];

    let isValid = true;
    for (const field of requiredFields) {
      if (field === 'caracteristics') {
        if (!newProduct.caracteristics || newProduct.caracteristics.length === 0) {
          isValid = false;
          break;
        }
      } else if (field === 'brand') {
        if (!newProduct.brand) {
          isValid = false;
          break;
        }
      } else if (field === 'categories') {
        if (!newProduct.categories || newProduct.categories.length === 0) {
          isValid = false;
          break;
        }
      } else if (!newProduct[field as keyof Product]) {
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      toast.error('El formulario no está completo. Por favor, rellene todos los campos obligatorios.');
      return;
    }

    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};

      const productData = {
        name: newProduct.name,
        model: newProduct.model,
        sku: newProduct.sku,
        price: newProduct.price,
        vat: newProduct.vat,
        stock: newProduct.stock,
        description: newProduct.description,
        caracteristics: newProduct.caracteristics,
        brand: newProduct.brand,
        categories: newProduct.categories,
        system: "tecnoficom",
        dataSheetURL: newProduct.dataSheetURL
      };
      const productDataString = JSON.stringify(productData);
      console.log(productDataString)
      const formData = new FormData();
      formData.append('productData', productDataString);
      if (viewImages && viewImages.length > 0) {
        formData.append('views_url', viewImages[0]);
      }
      if (productImage) {
        formData.append('product_url', productImage);
      }
      const response = await axios.post<Category[]>(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/products`,
        formData,
        {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      toast.success('Producto creado exitosamente');
      navigate('/dashboard'); 
    } catch (error) {
      toast.error('Error al crear el producto. Intente nuevamente.');
    }

  };

  return (
    <Wrapper>
      <ToastContainer />
      <h3 className="flex-1 text-tecnofi-text text-lg font-semibold text-center mt-6 mb-2">
        Crear un Producto
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="product_url">
            Imagen principal
          </label>
          <input
            className="hidden"
            id="product_url"
            type="file"
            onChange={handleProductImageChange}
          />
          <label
            htmlFor="product_url"
            className="flex justify-start items-center border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-faded-text cursor-pointer w-full px-4 h-[52px] bg-tecnofi-section"
          >
            {productImage ? (
              <p className="text-tecnofi-text font-medium">Imagen seleccionada</p>
            ) : (
              'Selecciona una imagen para reemplazar'
            )}
          </label>

          {productImage && productImage instanceof File && (
            <div className="mt-2">
              <img
                src={URL.createObjectURL(productImage)}
                alt="Imagen seleccionada"
                className="w-[100px] h-[auto] rounded-md"
              />
              <button
                type="button"
                className="mt-2 text-red-500"
                onClick={handleRemoveProductImage}
              >
                Eliminar imagen
              </button>
            </div>
          )}

          {productImage && typeof productImage === 'string' && (
            <div className="mt-2">
              <img
                src={productImage}
                alt="Imagen principal"
                className="w-[100px] h-[auto] rounded-md"
              />
            </div>
          )}
        </div>

        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="views_url">
            Imágenes adicionales
          </label>
          <input
            className="hidden"
            id="views_url"
            type="file"
            multiple
            onChange={handleViewImagesChange}
          />
          <label
            htmlFor="views_url"
            className="flex justify-start items-center border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-faded-text cursor-pointer w-full px-4 h-[52px] bg-tecnofi-section"
          >
            {viewImages && viewImages.length > 0 ? (
              <p className="text-tecnofi-text font-medium">Imágenes seleccionadas</p>
            ) : (
              'Selecciona imágenes adicionales'
            )}
          </label>
          <div className="mt-4">
            {(viewImages ?? []).map((file, index) => (
              <div key={index} className="flex items-center gap-2 mb-2">
                <img
                  src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                  alt={`Imagen adicional ${index}`}
                  className="w-[50px] h-[auto] rounded-md"
                />
                <button
                  type="button"
                  className="text-red-500"
                  onClick={() => handleRemoveViewImage(index)}
                >
                  Eliminar
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="name">
            Nombre
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="name"
            type="text"
            placeholder="Nombre"
            value={newProduct.name}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="model">
            Modelo
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="model"
            type="text"
            placeholder="Modelo"
            value={newProduct.model}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="sku">
            SKU
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="sku"
            type="text"
            placeholder="SKU"
            value={newProduct.sku || ''}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="price">
            Precio
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="price"
            type="number"
            placeholder="Precio"
            value={newProduct.price}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="price">
            IVA
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="vat"
            type="number"
            placeholder="IVA"
            value={newProduct.vat}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="stock">
            Stock
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="stock"
            type="text"
            placeholder="Stock"
            value={newProduct.stock}
            onChange={handleInputChange}
          />
        </div>

        <article className="relative flex flex-col">
          <label className="flex text-base font-medium mb-2">
            Seleccionar Categoría Nivel 1
          </label>
          <label
            className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
            onClick={() => setToggleCategoryLevelOne(!toggleCategoryLevelOne)}
          >
            {selectedCategoryLevelOne ? `${selectedCategoryLevelOne.name}` : 'Seleccionar Categoría Nivel 1'}
          </label>
          {toggleCategoryLevelOne && (
            <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 shadow-md max-h-60 overflow-y-auto max-w-[300px]">
              {categoriesLevelOne.map((category) => (
                <div
                  key={category.id}
                  className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section"
                  onClick={() => {
                    setSelectedCategoryLevelOne(category);
                    setToggleCategoryLevelOne(false);
                    setNewProduct((prevProduct) => ({
                      ...prevProduct,
                      categories: [String(category.id), prevProduct.categories[1] || ''],
                    }));
                  }}
                >
                  {category.name}
                </div>
              ))}
            </div>
          )}
        </article>

        <article className="relative flex flex-col">
          <label className="flex text-base font-medium mb-2">
            Seleccionar Categoría Nivel 2
          </label>
          <label
            className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
            onClick={() => setToggleCategoryLevelTwo(!toggleCategoryLevelTwo)}
          >
            {selectedCategoryLevelTwo ? `${selectedCategoryLevelTwo.name}` : 'Seleccionar Categoría Nivel 2'}
          </label>
          {toggleCategoryLevelTwo && (
            <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 shadow-md max-h-60 overflow-y-auto max-w-[300px]">
              {categoriesLevelTwo.map((category) => (
                <div
                  key={category.id}
                  className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section"
                  onClick={() => {
                    setSelectedCategoryLevelTwo(category);
                    setToggleCategoryLevelTwo(false);
                    setNewProduct((prevProduct) => ({
                      ...prevProduct,
                      categories: [prevProduct.categories[0] || '', String(category.id)],
                    }));
                  }}
                >
                  {category.name}
                </div>
              ))}
            </div>
          )}
        </article>

        <article className="relative flex flex-col mt-4">
          <label className="flex text-base font-medium mb-2">
            Seleccionar Marca
          </label>
          <label
            className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
            onClick={() => setToggleBrand(!toggleBrand)}
          >
            {selectedBrand ? `${selectedBrand.name}` : 'Seleccionar Marca'}
          </label>
          {toggleBrand && (
            <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 shadow-md max-h-60 overflow-y-auto max-w-[300px]">
              {brands.map((brand) => (
                <div
                  key={brand.id}
                  className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section"
                  onClick={() => {
                    setSelectedBrand(brand);
                    setToggleBrand(false);
                    setNewProduct((prevProduct) => ({
                      ...prevProduct,
                      brand: String(brand.id),
                    }));
                  }}
                >
                  {brand.name}
                </div>
              ))}
            </div>
          )}
        </article>

        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="description">
            Descripción
          </label>
          <textarea
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="description"
            placeholder="Descripción"
            value={newProduct.description || ''}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="caracteristics">
            Características
          </label>
          <textarea
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="caracteristics"
            placeholder="Características"
            value={Array.isArray(newProduct.caracteristics) ? newProduct.caracteristics.join(', ') : newProduct.caracteristics || ''}
            onChange={handleCaracteristicsChange}
          />
        </div>

        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="dataSheetURL">
            URL de la ficha técnica
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="dataSheetURL"
            type="url"
            placeholder="URL de la ficha técnica"
            value={newProduct.dataSheetURL || ''}
            onChange={handleInputChange}
          />
        </div>

        <button type="submit" className="mt-4 px-6 py-3 bg-tecnofi-primary text-white rounded-[8px] font-medium">
          Crear Producto
        </button>
      </form>
    </Wrapper>
  );
};

export default CreateProductForm;